{
  "all": "Все",
  "all_flats": "Все квартиры",
  "apply": "Применить",
  "avilable": "Доступные",
  "avilable_only": "Только доступные",
  "card_cookies": "При работе с данным сайтом вы принимаете условия использования файлов",
  "compass": {
    "n": "С",
    "s": "Ю",
    "w": "З",
    "e": "В"
  },
  "description": "Современный жилой комплекс бизнес класса",
  "download": "Скачать",
  "duplex": "Двухуровневая",
  "error": "Что-то пошло не так...",
  "finish": {
    "base": "Без отделки",
    "white": "Белая",
    "full": "Полная"
  },
  "status": {
    "free": "Продажа",
    "reserved": "Резерв",
    "sold": "Продано",
    "rent": "Аренда"
  },
  "level": "Уровень",
  "next": "Далее",
  "not_found": "Квартиры не найдены!",
  "pc": "шт",
  "per_page": "Показывать по:",
  "price_note": "Цена зависит от этажности и оснащения квартиры",
  "requestMsg": "Ваша заявка успешно отправлена! Мы свяжемся с вами в ближайшее время.",
  "requestPrice": "По запросу",
  "reset": "Сбросить все",
  "reserved": "В резерве",
  "sold": "Продано",
  "rent": "Аренда",
  "sale": "Доступно",
  "sorting": "Сортировка:",
  "agreement": ["Я согласен с условиями обработки", "персональных данных", ""],
  "callme": {
    "title": ["Заказать", "звонок"],
    "submit": "Заказать звонок",
    "info": "Оставьте свои контактные данные и наши менеджеры свяжутся с Вами в ближайшее время"
  },
  "fields": {
    "email": {
      "label": "E-mail",
      "ph": "Введите e-mail"
    },
    "name": {
      "label": "Имя",
      "ph": "Введите имя"
    },
    "parking": "Парковочное место",
    "phone": {
      "label": "Телефон",
      "ph": "Введите телефон"
    },
    "storeroom": "Кладовая"
  },
  "map": {
    "touch": "Чтобы переместить карту, проведите по ней двумя пальцами",
    "scroll": "Чтобы изменить масштаб, прокручивайте карту, удерживая клавишу Ctrl",
    "scrollMac": "Чтобы изменить масштаб, прокручивайте карту, удерживая клавишу  ⌘"
  },
  "nav": {
    "about": "О проекте",
    "architecture": "Архитектура",
    "design": "Дизайн квартир",
    "improvement": "Благоустройство и инфраструктура",
    "parking": "Парковки и складские помещения",
    "location": "Место расположения",
    "developer": "Команда проекта",
    "flats": "Выбор квартиры",
    "floor": "Этаж",
    "layout": "Планировка",
    "contacts": "Контакты",
    "documentation": "Документация",
    "cookies": "Cookies",
    "privacy": "Политика конфиденциальности",
    "creation": "Создание и продвижение",
    "contact_us": "Связаться с нами",
    "process": "Процесс строительства"
  },
  "request": {
    "title": ["Заявка на", "квартиру"],
    "text": "Оставьте свои контактные данные и наши менеджеры свяжутся с Вами в ближайшее время",
    "checks_caption": "Выберите «Парковочное место» или «Кладовая», менеджер подберет Вам подходящий вариант",
    "submit": "Отправить"
  },
  "paginator": {
    "next": "вперед",
    "back": "назад"
  },
  "params": {
    "area": "S, м²",
    "id": "№",
    "finish": "Отделка",
    "flat_number": "№ кв.",
    "floor": "Этаж",
    "rooms": "Комнат",
    "rooms_qty": "Кол-во комнат",
    "price": "Цена, €*",
    "price2": "Цена, тыс €",
    "type": "Тип квартиры",
    "with_furniture": "С мебелью",
    "without_furniture": "Без мебели",
    "status_caption": "Статус"
  },
  "table_sorting": {
    "floor": "По этажу",
    "id": "По № квартиры",
    "rooms": "По количеству комнат",
    "area": "По площади",
    "finish": "По отделке",
    "price": "По цене"
  },
  "validation": {
    "email": "Поле {field} заполнено неверно",
    "required": "Поле {field} обязательно"
  },
  "pages": {
    "architecture": {
      "title": "Архитектура"
    },
    "contacts": {
      "title": "Контакты",
      "address_caption": "Офис продаж",
      "contacts_caption": "Контакты",
      "time_caption": "Время работы",
      "time": "Будни"
    },
    "cookies": {
      "title": "Cookies"
    },
    "developer": {
      "title": "Команда проекта"
    },
    "documentation": {
      "title": "Документация"
    },
    "design": {
      "title": "Дизайн квартир"
    },
    "improvement": {
      "title": "Благоустройство \nи инфраструктура"
    },
    "index": {
      "title": "Panorama Residence",
      "title_0": ["СОВРЕМЕННЫЙ ЖИЛОЙ КОМПЛЕКС", "\nPANORAMA RESIDENCE ", "\nУЛИЦА ЛИЕЛИРБЕС 15, РИГА"],
      "title_1": [ null,"Архитектура", "\nжилого комплекса", null],
      "title_2": "Благоустройство \nи инфраструктура",
      "choose": "Выбрать квартиру",
      "more": "Подробнее",
      "from": "От",
      "price": "Цена за кв.м"
    },
    "flats-flat-id": {
      "title": "Квартира",
      "title_about": "О квартире",
      "title_plan": "План этажа",
      "back": "Назад",
      "share": "Поделиться",
      "btn_request": "Оставить заявку",
      "btn_download": "Скачать"
    },
    "flats": {
      "title": "Выбор квартиры",
      "sub_title": ["Выбор по параметрам", "Выбор по планировкам"],
      "filter": "Фильтр",
      "floors": "Этажи",
      "layouts": "Планировки",
      "table_link": "Смотреть"
    },
    "location": {
      "title": "Место расположения"
    },
    "parking": {
      "title": "Парковки \nи складские помещения"
    },
    "process": {
      "title": "Процесс строительства"
    },
    "privacy": {
      "title": "Политика конфиденциальности"
    }
  },
  "page_404": {
    "title": "Страница не найдена",
    "btn": "Выбрать квартиру"
  },
  "design": "Дизайн квартир",
  "close": "Закрыть"
}
